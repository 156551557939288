import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { EventService } from '../services/event.service';
import { take } from 'rxjs/operators';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit, OnDestroy {
  eventID: string;
  loading: boolean = true;
  agenda: any;
  agendaMod: any;
  speakers: any;
  faArrowLeft = faArrowLeft;

  agendaImage = new Image();

  agendaApprovalCode: any;

  // filteredAgenda = [
  //   'CM Plenary Session 1',
  //   'CM Room1',
  //   'CM Room2',
  //   'CM Room3',
  //   'CM Room4',
  //   'CM Room5',
  // ]

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.eventID = params.eventID;

      if (this.eventService.currentEvent) {

        this.agendaImage.src = "assets/" + this.eventService.currentEvent.events.name.trim().replace(/\s+/g, '-').toLowerCase() + '/' + 'agenda-bg' + '.jpg';

        console.log(this.eventService.currentEvent.agenda);
        this.agenda = [];
        this.agendaMod = [];
        this.agenda = [...this.eventService.currentEvent.agenda[Object.keys(this.eventService.currentEvent.agenda)[0]]];

        this.agendaMod = this.agenda?.length ? [...this.agenda] : [];

        // console.log(this.agendaMod);
        // console.log(this.agenda);

        this.agendaApprovalCode = this.eventService.currentEvent.events.agendaApprovalCode;
        console.log("Agenda Approval code is...." + this.agendaApprovalCode);



        // this.agendaMod?.forEach(element => {

        //   // console.log(typeof element.time_start)

        //   // Infinity
        //   let startOriginalNumber = Number(element.time_start.substring(0, 2)) + 3;

        //   // Meeting
        //   // let startOriginalNumber = Number(element.time_start.substring(0, 2)) + 4;
        //   // console.log(startOriginalNumber);

        //   // Infinity
        //   let endOriginalNumber = Number(element.time_end.substring(0, 2)) + 3;

        //   // Meeting
        //   // let endOriginalNumber = Number(element.time_end.substring(0, 2)) + 4;

        //   element.time_start = element.time_start.substring(2);
        //   element.time_end = element.time_end.substring(2);

        //   // console.log(element.time_start)

        //   element.time_start = startOriginalNumber + element.time_start;
        //   element.time_end = endOriginalNumber + element.time_end;

        // });

        this.eventService.getSpeakers().pipe(take(1)).subscribe(((response: any) => {
          // console.log(response);
          this.speakers = response;
          // End Loader
          this.loading = false;
        }));

      } else {
        this.router.navigate(['/event', this.eventID]);
        // this.loading = false;
      }

    });
  }

  ngOnDestroy() {

    // if (this.agendaMod) {

    //   this.agendaMod.forEach(element => {

    //     // console.log(typeof element.time_start);

    //     // Inifinty
    //     let startOriginalNumber = Number(element.time_start.substring(0, 2)) - 3;

    //     // Meeting
    //     // let startOriginalNumber = Number(element.time_start.substring(0, 2)) - 4;
    //     // console.log(startOriginalNumber);

    //     // Infinity
    //     let endOriginalNumber = Number(element.time_end.substring(0, 2)) - 3;

    //     // Meeting
    //     // let endOriginalNumber = Number(element.time_end.substring(0, 2)) - 4;

    //     element.time_start = element.time_start.substring(2);
    //     element.time_end = element.time_end.substring(2);

    //     // console.log(element.time_start)

    //     element.time_start = startOriginalNumber + element.time_start;
    //     element.time_end = endOriginalNumber + element.time_end;

    //   });
    // }
  }

}
