import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventService } from '../services/event.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../services/auth.service';
import { TimerService } from '../services/timer.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-auditorium',
  templateUrl: './auditorium.component.html',
  styleUrls: ['./auditorium.component.scss'],
})
export class AuditoriumComponent implements OnInit, AfterViewInit, OnDestroy {
  time = new Date();
  timeGMT: any;
  eventID: string;
  auditoriumID: string;
  currentMeeting: any;
  backgroundImage = new Image();
  mobileBackgroundImage = new Image();
  primaryColor: string;
  secondaryColor: string;
  activeTemplate: number;
  modalRef: BsModalRef;

  meeting = {
    liveVideoURL: '',
    chatURL: '',
    ExternalLinks: '',
  };

  loading: boolean = true;
  signatureEndpoint = 'https://zoom.cloudvie.live/'; // api new waiting
  sdkKey = environment.ZOOM_SDK_KEY;
  meetingNumber = '0'; // meeting id
  role = 0;
  userName =
    localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
  userEmail = localStorage.getItem('email');
  passWord = '111'; // pass , will get from api
  registrantToken = '';
  client = ZoomMtgEmbedded.createClient();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventService,
    private titleService: Title,
    private timerService: TimerService,
    private modalService: BsModalService,
    public httpClient: HttpClient,
    @Inject(DOCUMENT) _document
  ) {}
  async leaveMeeting() {
    await this.client.leaveMeeting();
  }
  ngOnInit(): void {
    this.leaveMeeting().then(() => {
      this.route.params.subscribe((params: Params) => {
        this.eventID = params.eventID;
        this.auditoriumID = params.auditoriumID.toLowerCase();

        if (this.eventService.currentEvent) {
          console.log(this.eventService.currentEvent);
          this.titleService.setTitle(
            this.eventService.currentEvent.events.name +
              ' Plenary' +
              ' - Cloudvie'
          );
          this.currentMeeting = this.eventService.currentEvent.rooms.find(
            (room) =>
              room.name
                .toLowerCase()
                .includes(
                  this.eventService.convertDashesintoSpaces(this.auditoriumID)
                )
          );
          console.log(this.currentMeeting);
          this.backgroundImage.src =
            'assets/' +
            this.eventService.currentEvent.events.name
              .trim()
              .replace(/\s+/g, '-')
              .toLowerCase() +
            '/' +
            'Meeting-Room' +
            '.jpg';
          this.mobileBackgroundImage.src =
            'assets/' +
            this.eventService.currentEvent.events.name
              .trim()
              .replace(/\s+/g, '-')
              .toLowerCase() +
            '/mobile-' +
            'Meeting-Room' +
            '.jpg';
          this.meeting.liveVideoURL = this.currentMeeting.stream;
          this.meetingNumber = this.currentMeeting.stream.split(';;')[0];
          this.passWord = this.currentMeeting.stream.split(';;')[1];

          this.meeting.chatURL =
            this.currentMeeting.supportChatID +
            `&username=${this.userName}&email=${this.userEmail}`;
          this.meeting.ExternalLinks = this.currentMeeting.ExternalLinks;
          this.primaryColor =
            this.eventService.currentEvent.events.primaryColor;
          this.secondaryColor =
            this.eventService.currentEvent.events.secondaryColor;
          this.activeTemplate = this.eventService.currentEvent.events.template
            ? this.eventService.currentEvent.events.template
            : 1;

          this.timerService.sendActivity(this.currentMeeting.id);
        } else {
          this.router.navigate(['/event', this.eventID]);
        }
      });
    });

    setInterval(() => {
      this.time = new Date();
      this.timeGMT = this.adjustForTimezone(new Date());
    }, 1000);

    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  adjustForTimezone(date: Date): Date {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
  }

  openModal(chatModal: TemplateRef<any>) {
    // console.log(link);

    let config = {
      ignoreBackdropClick: true,
      class: 'bg-modal',
    };

    this.modalRef = this.modalService.show(chatModal, config);
    // this.eventService.openPublicChat.next(true);
    // console.log(link);
  }
  ngAfterViewInit(): void {
    this.leaveMeeting().then(() => {
      let meetingSDKElement = document.getElementById('meetingSDKElement');
      this.route.params.subscribe((params: Params) => {
        if (this.eventService.currentEvent) {
          this.client?.init({
            debug: false,
            zoomAppRoot: meetingSDKElement,
            language: 'en-US',
            customize: {
              video: {
                isResizable: false,
                popper: {
                  disableDraggable: true,
                },
                viewSizes: {
                  default: {
                    width: 730,
                    height: 300,
                  },
                  // ribbon: {
                  //   width: 730,
                  //   height: 300,
                  // },
                },
              },
              meetingInfo: [
                'topic',
                'host',
                'mn',
                'pwd',
                'telPwd',
                'invite',
                'participant',
                'dc',
                'enctype',
              ],
              toolbar: {
                buttons: [
                  // {
                  //   text: 'Custom Button',
                  //   className: 'CustomButton',
                  //   onClick: () => {
                  //     console.log('custom button');
                  //   },
                  // },
                ],
              },
            },
          });

          this.getSignature();
        }
      });
    });
  }

  getSignature() {
    this.httpClient
      .post(this.signatureEndpoint, {
        // reqest to get signature
        meetingNumber: this.meetingNumber,
        role: this.role,
      })
      .toPromise()
      .then((data: any) => {
        if (data.signature) {
          this.startMeeting(data.signature);
        } else {
          console.log(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  startMeeting(signature) {
    //  signature from api
    try {
      this.client.join({
        sdkKey: this.sdkKey,
        signature: signature,
        meetingNumber: this.meetingNumber,
        password: this.passWord,
        userName: this.userName,
        userEmail: this.userEmail,
        tk: this.registrantToken,
      });
      // setTimeout(() => {
      //   if (this.meetingNumber !== '88928401276') {
      //     this.client.leaveMeeting()
      //     this.meetingNumber = '88928401276'
      //     this.getSignature()
      //   }
      // }, 5000);
    } catch (err) {
      console.log(err);
    }
  }
  ngOnDestroy() {
    this.timerService.sendEndActivity(this.timerService.endTimer());
    this.client
      .leaveMeeting()
      .then(() => {
        console.log('left meeting');
        if (window.location.pathname.includes('/event')) {
          location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
