<div class="my-container">
  <ng-template #customLoadingTemplate>
  </ng-template>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop:true }"
    [template]="customLoadingTemplate"></ngx-loading>
</div>

<app-header [eventID]="eventID"></app-header>


<div class="container-fluid agenda-bg text-center pt-5" [ngStyle]="{ 'background-image': 'url(' + agendaImage.src + ')'}">
  <!-- <h1 class="text-white display-4 mt-5">AGENDA</h1> -->
</div>

<div class="container mt-5 position-relative" *ngIf="agenda && speakers">


  <h3 [routerLink]="['/event', eventID]" class="pointer mt-4 mb-5 hover-color">
    <fa-icon [icon]="faArrowLeft" class=" mr-1 ml-0"></fa-icon>
    Back to the Main Hall
    <!-- Back to the Auditorium -->
  </h3>

  <div class="approvalCode position-fixed bg-white p-1">
    <!-- PP-WW-VV-2137812983 -->
    {{ agendaApprovalCode }}
  </div>

  <tabset [justified]="true">
    <tab heading="AGENDA">


      <!-- <div class="row agenda-row p-4 mt-3 mb-5" *ngFor="let hall of filteredAgenda; let index = index"> -->

      <!-- <div class="col-12 mb-3 hall">
          <h4><b> {{ hall }} </b></h4>
        </div> -->

      <!-- <div class="row topic-row w-100 mt-3" *ngFor="let topic of agenda[hall]"> -->
      <div *ngIf="agendaMod.length" class="pb-5 mb-5">
        <div class="row topic-row w-100 mt-3" *ngFor="let topic of agendaMod">
          <div class="col-xl-3 col-12">
            <p><b>Speaker(s): </b><br>{{ topic.speakers_name }}</p>
          </div>
          <div class="col-xl-6 col-12" >
            <div>
              <p class="mb-0"><b>Topic: </b><br></p> 
              <div [innerHTML]="topic.subject"></div> 
            </div>
          </div>
          <div class="col-12 col-xl-3">
            <p><b>Time in GMT: </b><br>{{ topic.time_start }} - {{ topic.time_end }}</p>
            <!-- <p><b>Time in KWT: </b><br>{{ topic.time_start }} - {{ topic.time_end }}</p> -->
            <!-- <button class="btn btn-success border-0 bg-primary" *ngIf="topic.liveNow">Live Now</button> -->
          </div>
        </div>
      </div>
      <div *ngIf="!agendaMod.length" class="text-center">
        <h2 class="mt-4">Agenda will be available Soon.</h2>
      </div>
      <!-- </div> -->


    </tab>
    <tab heading="SPEAKERS">

      <div class="pb-5 mb-5">
        <!-- Desktop -->
        <div class="row mt-5 mb-5 desktop-only" *ngFor="let speaker of speakers">
          <div class="col-xl-2">
            <img [src]="speaker.photo" [alt]="speaker.first_name" class="w-100 rounded-circle">
          </div>

          <div class="col-xl-10 helper-div">
            <span class="helper"></span>
            <div class="vertical-center">
              <h3 class="font-weight-bold">{{ speaker.first_name }} {{ speaker.last_name }}</h3>
              <p [innerHTML]="speaker.bio"></p>
            </div>
          </div>

        </div>

        <!-- Mobile -->
        <div class="row mt-5 mb-5 mobile-only" *ngFor="let speaker of speakers">
          <div class="col-8 offset-2">
            <img [src]="speaker.photo" [alt]="speaker.first_name" class="w-100 rounded-circle">
          </div>

          <div class="col-10 offset-1 text-center mt-3">
            <h3 class="font-weight-bold mb-2">{{ speaker.first_name }} {{ speaker.last_name }}</h3>
            <p [innerHTML]="speaker.bio"></p>
          </div>

        </div>
      </div>

    </tab>
  </tabset>
</div>

<app-footer></app-footer>