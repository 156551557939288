// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // SERVER: 'https://cors-anywhere.herokuapp.com/https://api-infinity.cloudvie.live/web/',
  SERVER: 'https://cors-anywhere.herokuapp.com/https://api-portal.cloudvie.live/web/',
  API_KEY: '9Ld0a26eb6cde16sZtAso3cg9XBfmy2N',
  merchantKey: '7Ld0a28bcd27hzyxeDjCgZ-qwzaOWJg3',
  CHATWEE_ID: '5ef0eea6a559034694271284',
  CLIENT_API_KEY: '6e6fdb01e6609de35fd5132a',
  SESSION_COOKIE_KEY: 'chatwee-SID-5ef0eea6a559034694271284',
  ZOOM_SDK_KEY: 'HWah2xdBdiqLHbRlsnc71FcjO09ORrVeBAnh',

  // Normal
  firebaseConfig: {
    apiKey: 'AIzaSyBWyZKcbrPR8Rkq013ydDYqdgPZlq9w_7o',
    authDomain: 'meet-cloudvie.firebaseapp.com',
    projectId: 'meet-cloudvie',
    storageBucket: 'meet-cloudvie.appspot.com',
    messagingSenderId: '924575441678',
    appId: '1:924575441678:web:c27acee98f1378e249ad5f',
    measurementId: 'G-3TJLMC958W',
  },

  // Infinity
  // firebaseConfig: {
  //   apiKey: "AIzaSyBsDZ2H46JcVZQ7Ls_iBwwfAx9A1AM9peg",
  //   authDomain: "cloud-vie-infinity.firebaseapp.com",
  //   projectId: "cloud-vie-infinity",
  //   storageBucket: "cloud-vie-infinity.appspot.com",
  //   messagingSenderId: "827152396511",
  //   appId: "1:827152396511:web:a2ecb72fdc3ab26c47396a",
  //   measurementId: "G-K0L2L7LLNY"
  // }
};

// https://cors-anywhere.herokuapp.com/https://api-infinity.cloudvie.live/web/
// https://cors-anywhere.herokuapp.com/https://api.cloudvie.live/web/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
