<div class="my-container">
  <ng-template #customLoadingTemplate> </ng-template>
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }"
    [template]="customLoadingTemplate"
  ></ngx-loading>
</div>

<app-header [eventID]="eventID"></app-header>
<!-- <div class="zoom-meeting-component">
  <div id="meetingSDKElement"></div>
</div> -->
<div
  [class]="'container-fluid background desktop-only template' + activeTemplate"
  [ngStyle]="{ 'background-image': 'url(' + backgroundImage.src + ')' }"
>
  <div class="liveBanner" *ngIf="meeting.ExternalLinks">
    <iframe
      [src]="meeting.ExternalLinks + '?muted=1&loop=1&autoplay=1' | vimeoUrl"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
  </div>

  <div class="liveVideo">
    <iframe
      [src]="meeting.liveVideoURL + '?loop=1&autoplay=0' | liveVimeoUrl"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
  </div>

  <div class="liveChat bg-white" *ngIf="!meeting.chatURL">
    <div class="h-100 w-100 helper-div">
      <span class="helper"></span>
      <h4 class="vertical-center pl-2 pr-2">
        Chat isn't Available now for this meeting
      </h4>
    </div>
  </div>

  <div class="liveChat bg-white" *ngIf="meeting.chatURL">
    <iframe
      *ngIf="meeting.chatURL"
      [src]="meeting.chatURL | liveVimeoUrl"
      width="100%"
      height="100%"
      frameborder="0"
      id="chatIframe"
    ></iframe>

    <div class="h-100 w-100 helper-div text-center d-none" id="chatPopup">
      <span class="helper"></span>
      <button
        class="btn btn-primary border-0 vertical-center"
        (click)="openModal(chatModal)"
        [ngStyle]="{ 'background-color': primaryColor }"
      >
        Open Chat
      </button>
    </div>
  </div>

  <div class="right-clock">
    <h1 [ngStyle]="{ color: secondaryColor }">
      <span *ngIf="activeTemplate == 3">Local Time</span
      ><br *ngIf="activeTemplate == 3" />
      {{ time | date : "hh:mm" }}
    </h1>
  </div>

  <div class="left-clock">
    <h1 [ngStyle]="{ color: secondaryColor }">
      <span *ngIf="activeTemplate == 3">GMT Time</span
      ><br *ngIf="activeTemplate == 3" />
      {{ timeGMT | date : "hh:mm" }}
    </h1>
  </div>
</div>

<!-- <app-footer></app-footer> -->

<!-- Mobile Version -->
<div
  [class]="
    'container-fluid mobile-background mobile-only position-relative mobileTemplate' +
    activeTemplate
  "
  [ngStyle]="{ 'background-image': 'url(' + mobileBackgroundImage.src + ')' }"
>
  <div class="mobile-right-clock">
    <h1 [ngStyle]="{ color: secondaryColor }">{{ time | date : "hh:mm" }}</h1>
  </div>

  <div class="mobile-left-clock">
    <h1 [ngStyle]="{ color: secondaryColor }">
      {{ timeGMT | date : "hh:mm" }}
    </h1>
  </div>

  <div class="mobile-liveVideo">
    <iframe
      [src]="meeting.liveVideoURL + '?loop=1&autoplay=0' | liveVimeoUrl"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
  </div>

  <div class="mobile-liveChat">
    <iframe
      *ngIf="meeting.chatURL"
      [src]="meeting.chatURL | liveVimeoUrl"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
  </div>
</div>

<ng-template #chatModal>
  <div class="modal-header border-0">
    <!-- <h4 class="modal-title pull-left">Modal Header</h4> -->
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="min-height: 500px">
    <iframe
      *ngIf="meeting.chatURL"
      [src]="meeting.chatURL | liveVimeoUrl"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
  </div>
</ng-template>
